code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #87CEFA; 
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  min-height: 100vh; /* Set a minimum height of the viewport height */
  background-color: #87CEFA; /* Move the background color here from body */
}

@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .app {
    justify-content: flex-start; /* Change justify-content on mobile */
    margin-top: 10vh;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  height: 80vh; /* Utilize 80% of the viewport height for container */
}

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  height: 80vh; /* Utilize 80% of the viewport height for container */
  width: 80vw;
}

@media (max-width: 768px) { /* Adjust the breakpoint as needed */
  .intro h1 {
    font-size: 1.1rem;
  }
}

.ready-btn {
  padding: 15px 30px;
  font-size: 24px;
  margin-top: 20px;
  background-color: #602f6b;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ready-btn:hover {
  background-color: #794185;
}

.practice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 450px; /* Reduce the width to make it more compact */
  background-color: #ADD8E6;
  border-radius: 8px;
}

.problem-display {
  display: flex;
  flex-direction: row;
  align-items: center; /* Align the items vertically */
  font-size: 26px;
  font-weight: bold;
  color: #602f6b;
  padding: 10px;
  margin: 10px;
  background: linear-gradient(to left, #8bc7d6, #9FC3D6);
  border-radius: 6px;
}

.message-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Distribute space between messages */
  font-size: 24px; /* Decrease font size */
  color: #602f6b;
  padding: 3px 5px;
  margin: 5px;
  min-height: 32px;
}

input[type="number"] {
  width: 50px;
  border: none;
  outline: none;
  font-size: 28px;
  font-weight: bold;
  color: #602f6b;
  background-color: transparent; /* Make the input field transparent */
  text-align: center;
}

/* Hide spin arrows in webkit-based browsers */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Styling for the "Level Up" message */
.level-up-message {
  color: lightpurple;
  font-size: 3rem;
  letter-spacing: 0.2em;
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fadeOut 2s ease-out forwards; /* Fade-out animation */
}

@media (max-width: 768px) { /* Adjust the breakpoint as needed */
  .level-up-message {
    font-size: 2rem; /* Reduce font size for mobile */
  }
}

/* Keyframes for the fade-out animation */
@keyframes fadeOut {
  0% { opacity: 0.75; }
  100% { opacity: 0; }
}

.launch-btn {
  padding: 15px 30px;
  font-size: 24px;
  margin-top: 20px;
  background-color: #602f6b;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.launch-btn:hover {
  background-color: #794185;
}

.button-container {
  display: flex;
  flex-direction: row;
  width: 400px;
  justify-content: space-around;
  margin-bottom: 20px;
}

.about {
  width: 600px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif; /* Or a suitable font for your website */
  line-height: 1.5;
  color: #333;
  background-color:  #ACDBF8; /* Subtle background color */
  border-radius: 10px;
  padding: 5px 20px;
}

@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .about {
    width: 90%; /* Set width to 100% for full viewport width */
    padding: 10px; /* Adjust padding for smaller screens */
    margin: 10px; /* Adjust margin for smaller screens */
  }
}

.title {
  font-size: 50px;
  color: #4f1d5a;
} 

@media only screen and (max-width: 768px) {
  .mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

hr {
  border-color: black;
  color: black;
  background-color: black;
  border-width: 1px;
  border-style: solid;
}